import { init } from "@sentry/nextjs";
import { getEnv } from "lib/getEnv";

init({
  release: `clearabee-hwa@${getEnv("VERSION")}`,
  dsn: getEnv("SENTRY_DSN"),
  // debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  environment: getEnv("SENTRY_ENVIRONMENT"),
  // We are ignoring these errors as they seem to be erroneously caused by a Chromium bug and/or various user extensions
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
    "Large Render Blocking Asset",
  ],
  enabled: process.env.NODE_ENV === "production",
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
